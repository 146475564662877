<template>
  <div class="page">
    <div class="intro-row">
      <div class="module">
        <div class="title">1. 安装教程：</div>
        <p class="intro">
          1. 点击获取快捷指令，根据步骤安装
          <br/>
          <span v-if="!isLite">注：该功能为会员功能，非会员用户可试用30次</span>
        </p>
        <div class="details">
          <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20240103135606326-image1.png" alt="" />
        </div>
      </div>
      <div class="module">
        <div class="title">2. 设置快捷指令启动方式：</div>
        <p class="intro">
          1. 依次进入：设置-辅助功能-触控-轻点背面-轻点两下
        </p>
        <p class="intro">2. 选择有鱼自动记账</p>
        <div class="details">
          <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20240103135637649-image2.png" alt="" />
        </div>
      </div>
      <div class="module">
        <div class="title">3. 授权快捷指令删除截图：</div>
        <p class="intro">
          1. 权限说明
        </p>
        <p class="desc">此步骤是为了防止给您的相册增加很多垃圾图片，并不会删除您的历史图片，此权限仅删除本次识别账单时自动产生的截图，可放心开启</p>
        <p class="intro">2、依次进入：设置-快捷指令-高级</p>
        <div class="details">
          <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20240103135706529-image3.png" alt="" />
        </div>
      </div>
      <div class="module">
        <div class="title">4. 授权有鱼读取剪贴板：</div>
        <p class="intro">
          1. 依次进入：设置-有鱼记账-从其他APP粘贴-允许
        </p>
        <p class="router" @click="jumpMobileSettingAction">点此直达></p>
        <div class="details">
          <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20240103135739213-image4.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { jsGoSetting } from '@/utils/bridge';

export default {
  name: 'MagicIntro',
  data() {
    return {
      isLite: false,
    };
  },
  mounted() {
    document.title = '自动记账教程';
    if (this.$route.path.includes('lite')) {
      this.isLite = true;
    }
  },
  methods: {
    jumpMobileSettingAction() {
      jsGoSetting();
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 750px;
  margin: auto;
  min-height: 100%;
  font-family: PingFangSC, PingFang SC;
  .intro-row {
    padding-top: 40px;
    margin: 0 20px;
    .module {
      margin-bottom: 40px;
      color: #000000;
      line-height: 40px;
      .details {
        margin-top: 40px;
      }
      img {
        width: 100%;
      }
      .title {
        font-size: 34px;
        font-weight: 600;
      }
      .intro {
        font-size: 26px;
        margin-left: 12px;
      }
      .desc {
        font-size: 24px;
        color: #868686;
        margin-left: 12px;
      }
      .router {
        font-size: 24px;
        color: #22A29D;
        margin-left: 20px;
      }
    }
  }
}
</style>
